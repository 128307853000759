import { create } from 'zustand';
import { VolumeIds } from 'types';

interface ActiveVolumesState {
  activeVolumesData: VolumeIds | null;
  // eslint-disable-next-line
  setActiveVolumeData: (activeVolumes: VolumeIds) => void;
}
const useActiveVolumes = create<ActiveVolumesState>((set) => ({
  activeVolumesData: null,
  setActiveVolumeData: (activeVolumes: VolumeIds) => {
    set({ activeVolumesData: activeVolumes });
  },
}));

export default useActiveVolumes;
