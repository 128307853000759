import MipMenu from 'components/menuInputs/MipMenu';
import InfoModal from 'components/Modals/infomodal/Infomodal';
import { AxialOrientation,
  CoronalOrientation,
  ExpandIcon,
  InfoIcon,
  MinimizeIcon,
  ResetIcon,
  SagittalOrientation,
  VesselReconstructionIcon,
  WindowingIcon } from 'components/ui/icons';
import UIIconMenu from 'components/ui/menus/icon/IconMenu';
import UiModal from 'components/ui/modal/Modal';
import useActiveVolumes from 'stores/activeVolumes';
import useWindowingStore from 'stores/windowing';
import useView from 'hooks/useView';
import React from 'react';
import { resetVesselReconstruction } from 'utils/cornerstone/vesselReconstruction';
import { getViewport, resetFilters, doesActiveVolumesHaveVesselData } from 'utils/utilities';
import useIsVesselReconstructionActive from 'stores/useVesselReconstruction';
import useMipStore from 'stores/mip';
import useProduct from 'stores/product';
import { PRODUCTS, VIEW_STATE } from '../../constants';
import OrientationMenu from './OrientationMenu';
import WindowingMenu from './WindowingMenu';

type defaultMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const DefaultCanvasMenu = ({ setTopBar }: defaultMenuProps) => {
  const [activeView, updateView] = useView();
  const isExpandedMenuOpen = activeView === VIEW_STATE.EXPANDED;
  const mipIcon = <div style={{ fontSize: '12px' }}>MIP</div>;
  const { setMipValue } = useMipStore();

  const { setWindowingValues } = useWindowingStore();
  const viewPort = getViewport();
  const orientation = viewPort?.defaultOptions?.orientation || 'axial';
  const iconMap = {
    axial: AxialOrientation,
    sagittal: SagittalOrientation,
    coronal: CoronalOrientation,
  };
  const { activeVolumesData } = useActiveVolumes();
  const {
    isVesselReconstructionActive,
    toggleVesselReconstruction,
  } = useIsVesselReconstructionActive();
  const hasVesselData = doesActiveVolumesHaveVesselData(activeVolumesData);
  const { product } = useProduct();
  const shouldHideVesselButton = product !== PRODUCTS.RES || !hasVesselData;
  /* in the button map below we are intentionally using the isDisabled prop to disable
    a range of different tools that are not available when vessel reconstruction is active */
  const buttons = [
    {
      icon: <UiModal icon={<InfoIcon />} content={<InfoModal />} />,
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: WindowingIcon,
      onClick: () => setTopBar(<WindowingMenu setTopBar={setTopBar} />),
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: iconMap[orientation],
      onClick: () => setTopBar(<OrientationMenu setTopBar={setTopBar} />),
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: mipIcon,
      onClick: () => setTopBar(<MipMenu setTopBar={setTopBar} />),
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: <VesselReconstructionIcon />,
      onClick: () => toggleVesselReconstruction(),
      isActive: isVesselReconstructionActive,
      hidden: shouldHideVesselButton,
    },
    {
      icon: ResetIcon,
      onClick: async () => {
        if (!isVesselReconstructionActive) {
          return resetFilters(setWindowingValues, setMipValue);
        }
        return resetVesselReconstruction();
      },
    },
  ];

  return (
    <UIIconMenu
      buttons={buttons}
      rightIcon={
        !isExpandedMenuOpen
          ? {
            icon: ExpandIcon,
            onClick: () => updateView(VIEW_STATE.EXPANDED),
          }
          : {
            icon: MinimizeIcon,
            onClick: () => updateView(VIEW_STATE.CASE),
          }
      }
    />
  );
};

export default DefaultCanvasMenu;
