import { Enums,
  RenderingEngine,
  cache,
  init as csInit,
  getRenderingEngine,
  setPreferSizeOverAccuracy,
  setUseSharedArrayBuffer,
  setVolumesForViewports,
  volumeLoader } from '@cornerstonejs/core';
import { cornerstoneNiftiImageVolumeLoader } from '@cornerstonejs/nifti-volume-loader';
import * as cornerstoneTools from '@cornerstonejs/tools';
import ToolGroup from '@cornerstonejs/tools/dist/types/store/ToolGroupManager/ToolGroup';
import CustomWindowLevelTool from 'utils/cornerstone/CustomWindowLevelTool';
import { RENDERING_ENGINE_ID, TOOL_GROUP_ID, VIEWPORT_ID } from '../../constants';
import { VolumeIds } from '../../types';
import { getDefaultWindowingValues } from '../utilities';

const {
  ToolGroupManager,
  StackScrollMouseWheelTool,
  ZoomTool,
  Enums: csToolsEnums,
  init: csTools3dInit,
  PanTool,
  StackScrollTool,
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;

export function setupTooling(): ToolGroup {
  const existingToolGroup = ToolGroupManager.getToolGroup(TOOL_GROUP_ID);
  let toolGroup;
  if (!existingToolGroup) {
    cornerstoneTools.addTool(CustomWindowLevelTool);
    cornerstoneTools.addTool(PanTool);
    cornerstoneTools.addTool(StackScrollMouseWheelTool);
    cornerstoneTools.addTool(ZoomTool);
    cornerstoneTools.addTool(StackScrollTool);

    toolGroup = ToolGroupManager.createToolGroup(TOOL_GROUP_ID);

    toolGroup.addTool(CustomWindowLevelTool.toolName);
    toolGroup.addTool(PanTool.toolName);
    toolGroup.addTool(ZoomTool.toolName);
    toolGroup.addTool(StackScrollTool.toolName);
    toolGroup.addTool(StackScrollMouseWheelTool.toolName);

    toolGroup.setToolActive(StackScrollTool.toolName);
    toolGroup.setToolActive(PanTool.toolName, {
      bindings: [{ mouseButton: MouseBindings.Secondary }],
    });
    toolGroup.setToolActive(CustomWindowLevelTool.toolName, {
      bindings: [{ numTouchPoints: 1 }],
    });
    toolGroup.setToolActive(ZoomTool.toolName, {
      bindings: [
        { numTouchPoints: 2 },
        {
          mouseButton: MouseBindings.Primary,
        },
      ],
    });
  } else {
    toolGroup = existingToolGroup;
  }
  return toolGroup;
}

export function setTransferFunctionForVolumeActor(
  volumeActor: any,
  volumeId: string,
  windowCenter?: number,
  windowWidth?: number,
) {
  const transferFunction = volumeActor.getProperty().getRGBTransferFunction(0);
  if (!transferFunction?.setMappingRange) {
    console.error('Transfer function not available');
    return;
  }

  const defaults = getDefaultWindowingValues(volumeId);
  const effectiveCenter = windowCenter ?? defaults.windowCenter;
  const effectiveWidth = windowWidth ?? defaults.windowWidth;

  const lower = effectiveCenter - effectiveWidth / 2;
  const upper = effectiveCenter + effectiveWidth / 2;

  transferFunction.setMappingRange(lower, upper);
}

export async function setupVolumes(
  volumeIds: {
    main: string;
    lesions: string[];
  },
  callback: any,
) {
  try {
    const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

    // Load lesion volumes
    await Promise.all(volumeIds.lesions.map((lesion) => volumeLoader.createAndCacheVolume(lesion)));
    await volumeLoader.createAndCacheVolume(volumeIds.main);
    // Prepare volume settings for setting volumes for viewports
    const volumeSettings = [
      { volumeId: volumeIds.main, callback },
      ...volumeIds.lesions.map((id) => ({ volumeId: id, callback })),
    ];

    // Set volumes for viewports
    await setVolumesForViewports(renderingEngine!, volumeSettings, [VIEWPORT_ID]);
  } catch (error) {
    console.error('Error loading volumes:', error);
  }
}

export async function setup(elementRef: any, volumeIds: { main: string; lesions: string[] }) {
  setUseSharedArrayBuffer(Enums.SharedArrayBufferModes.AUTO);
  setPreferSizeOverAccuracy(true);
  try {
    await csInit();
    await csTools3dInit();
    volumeLoader.registerVolumeLoader('nifti', cornerstoneNiftiImageVolumeLoader);
    const toolGroup = setupTooling();

    const existingRenderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
    if (!existingRenderingEngine) {
      const renderingEngine = new RenderingEngine(RENDERING_ENGINE_ID);
      const element = elementRef.current;

      if (element) {
        const viewportInput = {
          viewportId: VIEWPORT_ID,
          type: Enums.ViewportType.ORTHOGRAPHIC,
          element,
          defaultOptions: {
            orientation: Enums.OrientationAxis.AXIAL,
          },
        };

        renderingEngine.setViewports([viewportInput]);

        [viewportInput].forEach((viewport) => {
          if (!viewport.element) {
            throw new Error('viewport.element is undefined');
          }
          toolGroup.addViewport(viewport.viewportId, RENDERING_ENGINE_ID);
        });

        await setupVolumes(
          {
            main: `${`nifti:${volumeIds.main}`}`,
            lesions: volumeIds.lesions.map((lesion) => `nifti:${lesion}`),
          },
          ({ volumeActor }) => setTransferFunctionForVolumeActor(volumeActor, volumeIds.main),
        );
      }
      renderingEngine.render();
    }
  } catch (err) {
    console.error(err);
  }
}

export async function loadNewVolume(volumeIds: VolumeIds) {
  // The code below addresses an issue with browser tab reloads before volume display:
  // - Sets a maximum cache size of 800 MB, suitable for low/mid-tier mobile devices.
  // - Removes least recently used volumes to free up memory as needed.
  // - Based on testing, a 40 MB volume can consume about 250 MB of memory (September 2024).
  const eightHundredMB = 800 * 1024 * 1024;
  cache.setMaxCacheSize(eightHundredMB);
  cache.decacheIfNecessaryUntilBytesAvailable(eightHundredMB, [volumeIds.image]);

  const volumeId = `nifti:${volumeIds.image}`;

  const lesionVolumeIds = volumeIds.lesions.length > 0 ? volumeIds.lesions.map((lesion) => `nifti:${lesion}`) : [];
  const allVolumes = {
    main: volumeId,
    lesions: lesionVolumeIds,
  };

  // This callback is used to set the CT transfer function for the new volume actor
  await setupVolumes(
    allVolumes,
    ({ volumeActor }) => setTransferFunctionForVolumeActor(volumeActor, volumeId),
  );
  // Re-setup tooling if necessary. Depending on your application's design,
  // you might need to re-activate tools or re-bind events for the new volume.
  await setupTooling();
}
