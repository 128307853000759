import ImageDropdown from 'components/imagedropdown/ImageDropdown';
import PatientInfo from 'components/patientinfo/PatientInfo';
import Results from 'components/results/Result';
import ExpandedTopNav from 'components/topnav/expanded/ExpandedTopNav';
import TopNav from 'components/topnav/TopNav';
import Viewer from 'components/viewer/Viewer';
import Timeline from 'pages/Timeline';
import React, { useState } from 'react';
import useActiveVolumes from 'stores/activeVolumes';
import useProduct from 'stores/product';
import { CurrentCase } from 'types';
import getDeviceType from 'utils/devices';
import { shouldDisplayLesionMenu } from 'utils/heatmaps/heatmaps';
import formatImageData from 'utils/mapping/formatImageData';
import { DEVICE_ORIENTATION, VIEW_STATE } from '../constants';
import useView from '../hooks/useView';
import './MainView.css';

export default function MainView({ currentCase }: { currentCase: CurrentCase }) {
  const [activeView] = useView();
  const { patient } = currentCase;
  const selectedProduct = currentCase.selected_product;
  const { setProduct } = useProduct();
  const selectedImage = currentCase.selected_image;
  const niftiUrl = currentCase.nifti_urls;
  const studyDatetime = currentCase.study_datetime;
  const initialVolumeId = niftiUrl[parseInt(selectedImage, 10)]?.image;
  const vesselVolumeId = niftiUrl[parseInt(selectedImage, 10)]?.vessel;
  const deviceType = getDeviceType();
  const [showLesionMenu, setShowLesionMenu] = useState(
    shouldDisplayLesionMenu(
      currentCase.selected_product,
      currentCase.images[currentCase.selected_product][currentCase.selected_image].type,
    ),
  );

  const { images } = currentCase;
  const lesionVolumeIds = Object.values(
    currentCase.nifti_urls[currentCase.selected_image].lesions[currentCase.selected_product] || {},
  )
    .filter(Boolean)
    .map((url) => `${url}`);

  const initialVolumeIds = {
    image: initialVolumeId,
    lesions: lesionVolumeIds,
    vessel: vesselVolumeId,
  };
  const formattedImages = formatImageData(
    images,
    selectedProduct,
    niftiUrl,
    selectedImage,
    setShowLesionMenu,
  );

  const evaluateDeviceOrientation = () => (window.innerHeight > window.innerWidth
    ? DEVICE_ORIENTATION.PORTRAIT
    : DEVICE_ORIENTATION.LANDSCAPE);
  const [deviceOrientation, setDeviceOrientation] = React.useState<DEVICE_ORIENTATION>(
    evaluateDeviceOrientation(),
  );
  const { setActiveVolumeData } = useActiveVolumes();
  React.useEffect(() => {
    setProduct(selectedProduct);
    const handleResizeDimensions = () => {
      setDeviceOrientation(evaluateDeviceOrientation());
    };
    setActiveVolumeData(initialVolumeIds);
    window.addEventListener('resize', handleResizeDimensions);

    return () => {
      window.removeEventListener('resize', handleResizeDimensions);
    };
  }, []);
  const isTimelineView = activeView === VIEW_STATE.TIMELINE;
  const isPortraitOrientation = deviceOrientation === DEVICE_ORIENTATION.PORTRAIT;

  const showViews = isPortraitOrientation
    || deviceType !== 'mobile'
    || isTimelineView;

  const productResult = currentCase.product_results[selectedProduct];
  const productImages = currentCase.images[selectedProduct];

  const statuses = Object.values(productImages).map((img) => img.processingStatus);

  const caseView = (
    <div className='header-default-properties'>
      <TopNav />
      <PatientInfo patientData={patient} studyDate={studyDatetime} />
      <Results productResult={productResult} statuses={statuses} />
      <ImageDropdown formattedImages={formattedImages} />
    </div>
  );

  const expandedView = (
    <div className='header-default-properties'>
      <ExpandedTopNav productResult={productResult} statuses={statuses}/>
      <ImageDropdown formattedImages={formattedImages} />
    </div>
  );

  const timelineView = (
    <Timeline
      patientData={patient}
      studyDate={studyDatetime}
    />
  );

  const viewMap = {
    [VIEW_STATE.CASE]: caseView,
    [VIEW_STATE.EXPANDED]: expandedView,
    [VIEW_STATE.TIMELINE]: timelineView,
  };

  return (
    <div className='main-view'>
      {showViews && <div>{viewMap[activeView]}</div>}
      <Viewer initialVolumeIds={initialVolumeIds} showLesionMenu={showLesionMenu} />
    </div>
  );
}
