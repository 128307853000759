/* eslint-disable */
import { WindowingValue } from 'types';
export enum PRODUCTS {
  RES = 'res',
  FDA = 'fda',
  CE = 'ce',
}
export const VESSEL_RECONSTRUCTION_TOOL_GROUP_ID =
  "VESSEL_RECONSTRUCTION_TOOL_GROUP_ID";
export const TOOL_GROUP_ID = 'STACK_TOOL_GROUP_ID';
export const VIEWPORT_ID = 'CT_NIFTI_AXIAL';
export const RENDERING_ENGINE_ID = 'MyRenderingEngine';
export const DEFAULT_CTA_VALUES: WindowingValue = {
  windowWidth: 500,
  windowCenter: 180,
};
export const DEFAULT_NCCT_VALUES: WindowingValue = {
  windowWidth: 40,
  windowCenter: 40,
};
export enum IMAGE_TYPES {
  NCCT = 'NCCT',
  CTA = 'CTA',
}

export enum DEVICE_ORIENTATION {
  PORTRAIT = 'Portrait',
  LANDSCAPE = 'Landscape',
}

export enum NIFTI_ORIENTATIONS {
  AXIAL = 'axial',
  SAGITTAL = 'sagittal',
  CORONAL = 'coronal',
}

export enum LESIONS {
  HEMO = 'hemo',
  ISCHEMIA = 'ischemia',
  CLOT = 'clot',
}
export enum VIEW_STATE {
  CASE = 'case',
  EXPANDED = 'expanded',
  TIMELINE = 'timeline',
}

export enum ANATOMICAL_AXES {
  SAGITTAL = 0,
  CORONAL = 1,
  AXIAL = 2,
}

export const IS_IN_DEV = (() => {
  const isDevMode = process.env.REACT_APP_TEST_MODE === 'true';
  return isDevMode;
})();
