import { Types } from '@cornerstonejs/core';
import SliderMenu from 'components/ui/menus/slider/SliderMenu';
import useMipStore from 'stores/mip';
import React, { useState } from 'react';
import getCurrentSliceSpacing from 'utils/cornerstone/volumeUtils';
import { getViewport } from 'utils/utilities';
import { ANATOMICAL_AXES } from '../../constants';

type MipMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

function MipMenu({ setTopBar }: MipMenuProps) {
  const { mipValue, setMipValue } = useMipStore();
  const viewport = getViewport() as Types.IVolumeViewport;

  // Get current axis and spacing information
  const axisIndex = ANATOMICAL_AXES[viewport.defaultOptions.orientation.toUpperCase()];
  const totalSlices = viewport.getImageData().dimensions[axisIndex];

  // Calculate maximum and initial values
  const maxSlices = Math.min(Math.floor(totalSlices / 3), 50);

  // Manage slider state
  const [sliderValue, setSliderValue] = useState({
    currentSlice: 0,
    maxSlice: maxSlices,
  });

  const handleSliderChange = (newSliceCount: number) => {
    const boundedSliceCount = Math.max(Math.min(newSliceCount, maxSlices), 0);
    const sliceSpacing = getCurrentSliceSpacing();
    if (sliceSpacing !== 0) {
      const newThickness = boundedSliceCount * sliceSpacing;

      setSliderValue({
        currentSlice: boundedSliceCount,
        maxSlice: maxSlices,
      });
      const displayValue = newThickness + sliceSpacing;

      setMipValue(newThickness, displayValue);
    }
  };

  /* this effect handles when the mip value is changed by the user aside from
  the slider change, and along with setting the initial value when the menu is opened
  */
  const spacingOnAxis = getCurrentSliceSpacing();
  if (spacingOnAxis !== 0) {
    const activeSliderIndex = Math.round(mipValue / spacingOnAxis) - 1;
    const sliderIsOutOfSync = activeSliderIndex !== sliderValue.currentSlice;
    if (sliderIsOutOfSync) {
      setSliderValue({
        currentSlice: activeSliderIndex,
        maxSlice: maxSlices,
      });
    }
  }

  return (
    <SliderMenu
      menuContent={{
        displayValue: 'MIPs',
        valueExtension: sliderValue.currentSlice > 1 ? 'slices' : 'slice',
      }}
      sliderValue={sliderValue}
      handleSliderChange={handleSliderChange}
      setTopBar={setTopBar}
    />
  );
}

export default MipMenu;
