import UIDropdown from 'components/ui/dropdown/Dropdown';
import useActiveVolumes from 'stores/activeVolumes';
import React from 'react';
import { formattedImageData, VolumeIds } from 'types';
import { loadNewVolume } from 'utils/cornerstone/setup';
import { applyDefaultColorMap, getDefaultLesion } from 'utils/heatmaps/heatmaps';
import { getViewport } from 'utils/utilities';
import './ImageDropdown.css';
import StatusIndicator from './StatusIndicator';

export const handleDropdownItemClick = async (
  activeVolumeIds: VolumeIds,
  setActiveLesion: React.Dispatch<React.SetStateAction<string>>,
  isVesselReconstructionActive?: boolean,
  toggleVesselReconstruction?: () => void,
  setActiveVolumeData?: React.Dispatch<React.SetStateAction<any>>,
) => {
  // If vessel reconstruction is active, deactivate it first
  if (isVesselReconstructionActive && toggleVesselReconstruction) {
    await toggleVesselReconstruction();
  }

  setActiveVolumeData(activeVolumeIds);

  await loadNewVolume(activeVolumeIds);

  const defaultLesion = getDefaultLesion();
  setActiveLesion(defaultLesion);
  if (defaultLesion) {
    applyDefaultColorMap();
  }
  const viewport = getViewport();
  if (viewport) {
    viewport.render();
  }
};

type ImageDropdownProps = {
  formattedImages: formattedImageData[];
};
const ImageDropdown = ({ formattedImages }: ImageDropdownProps) => {
  const { activeVolumesData } = useActiveVolumes();

  // Calculate initial index without side effects
  const getActiveIndex = () => {
    if (activeVolumesData) {
      return formattedImages.findIndex((img) => img.image === activeVolumesData.image);
    }
    return formattedImages.findIndex((img) => img.selectedImage);
  };

  const [selectedImageIndex, setSelectedImageIndex] = React.useState(getActiveIndex());

  const renderImageItem = (item: formattedImageData) => (
    <div className="image-dropdown-item">
      <span>{item.label}</span>
      {item.processingStatus && <StatusIndicator status={item.processingStatus} />}
    </div>
  );

  const renderHeader = (selectedItem: formattedImageData) => (
    <div className="dropdown-header-content">
      {selectedItem.processingStatus && <StatusIndicator status={selectedItem.processingStatus} />}
    </div>
  );

  return (
    <UIDropdown
      items={formattedImages}
      activeIndex={selectedImageIndex}
      setActiveIndex={setSelectedImageIndex}
      renderItem={renderImageItem}
      renderHeader={renderHeader}
    />
  );
};

export default ImageDropdown;
