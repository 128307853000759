import { eventTarget } from '@cornerstonejs/core';
import { Enums as NiftiEnums } from '@cornerstonejs/nifti-volume-loader';
import { useCallback, useEffect, useRef, useState } from 'react';

/** Hook to track NIFTI volume loading progress */
export default function useLoadingProgress() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadStartTime = useRef(0);

  // Constants
  const MIN_LOAD_TIME = 1000;
  const EXCLUDED_VOLUMES = ['clot', 'hemo', 'acute'];
  function isExcludedVolume(volumeId: string) {
    return EXCLUDED_VOLUMES.some((excluded) => volumeId.toLowerCase().includes(excluded));
  }

  const handleProgress = useCallback((event: any) => {
    const { volumeId, loaded, total } = event.detail.data;
    if (isExcludedVolume(volumeId)) {
      return;
    }

    setIsLoading(true);
    if (loadStartTime.current === 0) {
      loadStartTime.current = Date.now();
    }

    setLoadingProgress(Math.round((loaded / total) * 100));
  }, []);

  const handleLoaded = useCallback((event: any) => {
    const { volumeId } = event.detail.data;
    if (isExcludedVolume(volumeId)) {
      return;
    }
    const timeElapsed = Date.now() - loadStartTime.current;
    const delay = Math.max(0, MIN_LOAD_TIME - timeElapsed);

    const resetState = () => {
      setIsLoading(false);
      setLoadingProgress(0);
      loadStartTime.current = 0;
    };

    if (delay > 0) {
      setTimeout(resetState, delay);
    } else {
      resetState();
    }
  }, []);

  useEffect(() => {
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_PROGRESS, handleProgress);
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleLoaded);

    return () => {
      eventTarget.removeEventListener(NiftiEnums.Events.NIFTI_VOLUME_PROGRESS, handleProgress);
      eventTarget.removeEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleLoaded);
    };
  }, []);

  return { isLoading, loadingProgress };
}
